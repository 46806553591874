import { BehaviorSubject } from "rxjs";

import { fetchWrapper, history, config, util } from "./../_helpers";
const userSubject = new BehaviorSubject(null);

//const baseUrl='https://authapi.digitallive24.com' ;

const billService = {
  createBill,
  updateBill,
  getUserBill,
  getUserBills,
  delete: _delete,
  user: userSubject.asObservable(),
  get userValue() {
    return userSubject.value;
  },
};

export default billService;

function getUserBill(id) {
  const payUrl = config && config.payUrl;
  return fetchWrapper.get(`${payUrl}/api/bills/${id}`);
}

function getUserBills(id) {
  const payUrl = config && config.payUrl;
  return fetchWrapper.get(`${payUrl}/api/bills/${id}`);
}

function createBill(params) {
  const payUrl = config && config.payUrl;
  return fetchWrapper.post(`${payUrl}/api/new-order`, params);
}

// function updateBill(id, params) {
//   const payUrl = config && config.payUrl;
//   return fetchWrapper.put(`${payUrl}/user/${id}`, params).then((user) => {
//     // update stored user if the logged in user updated their own record
//     if (user.id === userSubject.value.id) {
//       // publish updated user to subscribers
//       user = { ...userSubject.value, ...user };
//       userSubject.next(user);
//     }
//     return user;
//   });
// }

function updateBill(id, params) {
  const payUrl = config && config.payUrl;
  return fetchWrapper.put(`${payUrl}/api/order/${id}`, params).then((user) => {
    // update stored user if the logged in user updated their own record
    if (user.id === userSubject.value.id) {
      // publish updated user to subscribers
      user = { ...userSubject.value, ...user };
      userSubject.next(user);
    }
    return user;
  });
}

// prefixed with underscore because 'delete' is a reserved word in javascript
function _delete(id) {
  const payUrl = config && config.payUrl;
  return fetchWrapper.delete(`${payUrl}/user/${id}`).then((x) => {
    // auto logout if the logged in user deleted their own record
    if (id === userSubject.value.id) {
      logout();
    }
    return x;
  });
}
