
const getParameterByName = (e,t=window.location.href)=>{
  e=e.replace(/[\[\]]/g,"\\$&");var n=new RegExp("[?&]"+e+"(=([^&#]*)|&|#|$)").exec(t);
  return n?n[2]?decodeURIComponent(n[2].replace(/\+/g," ")):"":null
}

const util = {
    getParameterByName    
}

export default  util;