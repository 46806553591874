import React from 'react';
import { Link } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { util,config } from '../_helpers';

import { accountService, alertService } from '../_services';
import { gapi } from 'gapi-script';
import { GoogleLogin } from 'react-google-login';

function Register({ history }) {
    const initialValues = {
        firstName: '',
        lastName: '',
        email: '',
        phone:'',
        password: '',
        confirmPassword: '',
        profilePic:'',
        acceptTerms: true
    };
     const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    const initGoogleClient = () => {
         gapi.client.init({
                clientId: config.Google_Client_Id,
                scope:['email']
         });
    }
    gapi.load('client:auth2', initGoogleClient);

    const redirectPostSuccess = (token) =>{
         //alertService.success('Registration successful, please check your email for verification instructions', { keepAfterRouteChange: true });
         let redirectUrl= util.getParameterByName('redirect');
         window.location.href=redirectUrl+'?token='+token;
    }

    const validationSchema = Yup.object().shape({
        firstName: Yup.string()
            .required('First Name is required'),
        email: Yup.string()
            .email('Email is invalid')
            .required('Email is required'),
        phone: Yup.string()
            .matches(phoneRegExp,'Phone is invalid')
            .required('Phone is required'),
        password: Yup.string()
            .min(6, 'Password must be at least 6 characters')
            .required('Password is required'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Confirm Password is required'),
        acceptTerms: Yup.bool()
            .oneOf([true], 'Accept Terms & Conditions is required')
    });

    const onSubmit =(fields, { setStatus, setSubmitting }) =>{
        console.log('field',fields);
        setStatus && setStatus();
        accountService.register(fields)
            .then((res) => {
                console.log('saved');
                redirectPostSuccess(res.user.token);
            })
            .catch(error => {
                setSubmitting(false);
                alertService.error(error);
            });
    }

    const gLoginSuccess =(gData) => {
        console.log('google logged in success', gData);
        if(gData.profileObj){
            let newUser =initialValues;
            newUser.firstName = gData.profileObj.givenName;
            newUser.lastName = gData.profileObj.familyName;
            newUser.email = gData.profileObj.email;
            newUser.phone= "";
            newUser.password= gData.tokenId;
            newUser.confirmPassword=gData.tokenId;
            newUser.profilePic = gData.imageUrl;
            newUser.isEmailVerified=true;
            newUser.acceptTerms = true;
            newUser.thirdPartySignup="GOOGLE";
            console.log(newUser);
            onSubmit(newUser,{setStatus:()=>true,setSubmitting:()=>true});
        }
    }

    const gLoginFailed = (err) => {
        console.log('google log in failed', err);

    }

    return (
        <div>
        
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ errors, touched, isSubmitting }) => (
                <Form>
                
                    {<h3 className="card-header">Create New Account</h3>}
                    <div style={{textAlign:"center"}} className="form-group">
                            <GoogleLogin
                            clientId={config.Google_Client_Id}
                            buttonText="SIGNUP WITH GOOGLE"
                            onSuccess={gLoginSuccess}
                            onFailure={gLoginFailed}
                            cookiePolicy={'single_host_origin'}
                          />
                        </div>
                <div style={{width:"100%",color:"#ccc",textAlign:"center"}}>{'---------- OR ----------'}</div>

                    <div className="card-body">
                        <div className="form-row">
                            <Field name="profilePic" type="text" style={{visibility:'hidden',height:"1px",width:"1px"}} />
                            <div className="form-group col-5">
                                <label>First Name</label>
                                <Field name="firstName" type="text" className={'form-control' + (errors.firstName && touched.firstName ? ' is-invalid' : '')} />
                                <ErrorMessage name="firstName" component="div" className="invalid-feedback" />
                            </div>
                            <div className="form-group col-5">
                                <label>Last Name</label>
                                <Field name="lastName" type="text" className={'form-control' + (errors.lastName && touched.lastName ? ' is-invalid' : '')} />
                                <ErrorMessage name="lastName" component="div" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Email</label>
                            <Field name="email" type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                            <ErrorMessage name="email" component="div" className="invalid-feedback" />
                        </div>
                        <div className="form-group">
                            <label>Phone</label>
                            <Field name="phone" type="phone" className={'form-control' + (errors.phone && touched.phone ? ' is-invalid' : '')} />
                            <ErrorMessage name="phone" component="div" className="invalid-feedback" />
                        </div>
                        <div className="form-row">
                            <div className="form-group col">
                                <label>Password</label>
                                <Field name="password" type="password" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                                <ErrorMessage name="password" component="div" className="invalid-feedback" />
                            </div>
                            <div className="form-group col">
                                <label>Confirm Password</label>
                                <Field name="confirmPassword" type="password" className={'form-control' + (errors.confirmPassword && touched.confirmPassword ? ' is-invalid' : '')} />
                                <ErrorMessage name="confirmPassword" component="div" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="form-group form-check" style={{visibility:"hidden",height:"2px",overflow:"hidden"}}>
                            <Field type="checkbox" name="acceptTerms" id="acceptTerms" className={'form-check-input ' + (errors.acceptTerms && touched.acceptTerms ? ' is-invalid' : '')} />
                            <label htmlFor="acceptTerms" className="form-check-label">Accept Terms & Conditions</label>
                            <ErrorMessage name="acceptTerms" component="div" className="invalid-feedback" />
                        </div>
                        <div className="form-group" style={{float:'right', marginBottom:"20px"}}>
                            
                            <Link to="login" style={{marginRight:"20px"}} className="btn btn-link">Cancel</Link>
                            <button type="submit" disabled={isSubmitting} className="btn btn-primary">
                                {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                Register
                            </button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
        </div>
    )
}

export { Register }; 