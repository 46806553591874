import React, { useState, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { accountService, alertService } from "../../_services";
import { util } from "../../_helpers";
import * as Yup from "yup";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-responsive-list'
import 'react-responsive-list/assets/index.css'

function Members(props) {

  const user = accountService.userValue;

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    role: "",
    phone: "",
    password: "",
    mpin: "",
    confirmPassword: "",
    acceptTerms: true,
    isIAM: true,
    parentUser: user.user.sub,
  };

 
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    email: Yup.string().email("Email is invalid").required("Email is required"),
    phone: Yup.string()
      .matches(phoneRegExp, "Phone is invalid")
      .required("Phone is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
    role: Yup.string().required("Role is required"),
    mpin: Yup.string()
      .required("MPIN field required")
      .min(4, "Must be exactly 4 digits")
      .max(4, "Must be exactly 4 digits"),
  });

  const [isMember, setIsMember] = useState(false);

  const [membersData, setMembersData] = useState([]);
  const [roleData, setRoleData] = useState([]);

  const handleMember = () => {
    setIsMember(true);
  };
   
   function memberData() {
    accountService.getMember().then((res) => {
      setMembersData(res);
    });
  }

  function getRolesData() {
    accountService.getRoles().then((res) => {
      setRoleData(res);
    });
  }

  useEffect(() => {
    getRolesData();
    memberData();
  }, []);

  const postSuccess = (props) => {
    setIsMember(false);
        memberData();
        //setSubmitting(false);
        props.showModal(false);
  }

  function onSubmit(fields, { resetForm  }) {
    //setStatus();
    if(!fields.id){
    accountService
      .create(fields)
      .then((res) => {
         postSuccess(props);
      })
      .catch((error) => {
        setSubmitting(false);
        alertService.error(error);
      });
    }else{
    accountService
      .update(fields.id,fields)
      .then((res) => {
         postSuccess(props);
      })
      .catch((error) => {
        //setSubmitting(false);
        alertService.error(error);
      });
    }
    resetForm();
  }

  const handleMangeMem = () => {
    setIsPermission(false);
  };

  const editMember = (id) => {
      let selectedMember=membersData.filter(m => m.id == id);
      return addNewMember(selectedMember[0]);
  }

  const addNewMember = (selMember) => {
    return <Formik
          enableReinitialize
          initialValues={selMember||initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ errors, touched, isSubmitting , resetForm }) => (
            <Form>
              {/* <div style={{textAlign:"center",marginBottom:"20px"}}><img src="https://digisign24.s3.ap-south-1.amazonaws.com/users-media/web-assets/logo.png" width="300px" height="auto" style={{}}/></div>
                    <h3 className="card-header">Member Register</h3> */}
              <div className="card-body">
                <div className="form-row">
                  <div className="form-group col-5">
                    <label>First Name*</label>
                    <Field
                      name="firstName"
                      type="text"
                      className={
                        "form-control" +
                        (errors.firstName && touched.firstName
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="firstName"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="form-group col-5">
                    <label>Role</label>
                    {/* <Field name="lastName" type="text" className={'form-control' + (errors.lastName && touched.lastName ? ' is-invalid' : '')} /> */}
                    <Field
                      as="select"
                      name="role"
                      className={
                        "form-control" +
                        (errors.role && touched.role ? " is-invalid" : "")
                      }
                    >
                      <option disabled value="">
                        Select Role
                      </option>
                      
                      {roleData.map(r => 
                        <option value={r.id}>{r.roleName}</option>
                      )}
                    </Field>
                    <ErrorMessage
                      name="role"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-5">
                    <label>Email*</label>
                    <Field
                      name="email"
                      type="text"
                      className={
                        "form-control" +
                        (errors.email && touched.email ? " is-invalid" : "")
                      }
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="form-group col-5">
                    <label>MPIN</label>
                    <Field
                      name="mpin"
                      type="number"
                      min={1000}
                      max={9999}
                      className={
                        "form-control" +
                        (errors.mpin && touched.mpin ? " is-invalid" : "")
                      }
                    />
                    <ErrorMessage
                      name="mpin"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </div>
               
                <div className="form-group">
                  <label>Phone</label>
                  <Field
                    name="phone"
                    type="phone"
                    className={
                      "form-control" +
                      (errors.phone && touched.phone ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="phone"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
                <div className="form-row">
                  <div className="form-group col">
                    <label>Password</label>
                    <Field
                      name="password"
                      type="password"
                      className={
                        "form-control" +
                        (errors.password && touched.password
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="form-group col">
                    <label>Confirm Password</label>
                    <Field
                      name="confirmPassword"
                      type="password"
                      className={
                        "form-control" +
                        (errors.confirmPassword && touched.confirmPassword
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="confirmPassword"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </div>
               
                <div style={{padding:'10px',width:'100%',textAlign:'right'}}>
               <button class="button is-ghost" onClick={() => {resetForm(),setIsMember(false), props.showModal(false)}} style={{marginRight:'20px'}}>Cancel</button>
              <button type="submit"  disabled={isSubmitting} class="button is-primary" >{isSubmitting && (
                      <span className="spinner-border spinner-border-sm mr-1"></span>
                    )}
                    Save</button>
              </div>
              </div>
            </Form>
          )}
        </Formik>
  }

  return (
    <div>
      <div className="w-100">
        <div>
         <h4 style={{ padding: "10px 0px",display:"inline-block" }}>Members</h4>
          <button
            style={{float:'right'}}
          className="text-primary border-only-btn"
            onClick={handleMember}
          >
            + Add Member
          </button>
        </div>
      </div>
      
        <div>
          <Table
            cellSpacing="5px"
            cellPadding="2px"
            align="center"
            style={{ width: "100%",marginTop:"10px" }}
          >
            <Thead>
              <Tr style={{ borderBottom: "1px solid #cacaca" }}>
                <Th>Name</Th>
                <Th>Email</Th>
                <Th>Role</Th>
                <Th>Phone</Th>
                <Th ></Th>
              </Tr>
            </Thead>
            <Tbody>
              {membersData.length
                ? membersData.map((mem) => {
                    let role = roleData.filter((rl) => rl.id === mem.role);

                    return (
                      <Tr style={{ borderBottom: "1px solid #cacaca" }}>
                        <Td>{mem.firstName}</Td>
                        <Td>{mem.email}</Td>
                        <Td>{role.length ? role[0].roleName : ""}</Td>
                        <Td>{mem.phone}</Td>
                        <Td>
                        
                        <button onClick={()=>{props.showModalContent(editMember(mem.id),'Edit Member'),props.showModal(true)}} className="text-primary border-only-btn">Edit</button>
                        </Td>
                                              </Tr>
                    );
                  })
                : ""}
            </Tbody>
          </Table>
        </div>
      
        {isMember && !props.modalState && props.showModalContent(addNewMember(),'Add New Member')}
     

      
    </div>
  );
}

export { Members };
