import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import { fetchWrapper, history, config, util } from './../_helpers';
import { Role } from "../_helpers";
import { accountService } from "../_services";
import { Nav, PrivateRoute, Alert } from "../_components";
import { Home } from "../home";
import { View } from "../admin/users/View";
import { Admin } from "../admin";
import { Account } from "../account/Index";

function App() {
  const { pathname } = useLocation();
  const [user, setUser] = useState({});

  useEffect(() => {
    const subscription = accountService.user.subscribe((x) => setUser(x));
    return subscription.unsubscribe;
  }, []);

  console.log(pathname);

  return (
    <div className={"app-container"}>
      {false && <Nav />}
      <Alert />
      <Switch>
        <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
        <PrivateRoute exact path="/" component={Home} />
        <PrivateRoute path="/profile/view/:id" component={View} />
        <PrivateRoute
          path="/admin"
          aduser={user && user.user}
          roles={[Role.Super_Admin]}
          component={Admin}
        />
        <Route path="/account" component={Account} />
        <Redirect from="*" to="/" />
      </Switch>
    </div>
  );
}

export { App };
