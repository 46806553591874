import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { accountService } from '../_services';


function PrivateRoute({ component: Component, roles:roles, aduser:aduser ,...rest }) {
    return (
        <Route {...rest} render={props => {
            let user = accountService.userValue;
            user = aduser || user;
            if (!user) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />
                // return <Redirect to={{ pathname: '/profile', state: { from: props.location } }} />
            }
            // check if route is restricted by role
            if (roles && user&& roles.length && roles.indexOf(user.role) === -1) {
                // role not authorized so redirect to home page
                return <Redirect to={{ pathname: '/'}} />
            }
            // authorized so return component
            return <Component {...props} />
        }} />
    );
}

export { PrivateRoute };