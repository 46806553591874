import React from "react";
import { Router } from "react-router-dom";
// import { render } from 'react-dom';
import { createRoot } from "react-dom/client";

import { history } from "./_helpers";
import { accountService } from "./_services";
import { App } from "./app";

import "./styles.css";

// setup fake backend
import { configureFakeBackend } from "./_helpers";
configureFakeBackend();

// attempt silent token refresh before startup
accountService.refreshToken().finally();

// function startApp() {
//     render(
//         <Router history={history}>
//             <App />
//         </Router>,
//         document.getElementById('app')
//     );
// }
const container = document.getElementById("app");
const root = createRoot(container);

root.render(
  <Router history={history}>
    <App />
  </Router>
);
